import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/app.scss";
import Api from "./api";
import { envType } from "./plugins/environment";
import "./plugins/environment";
import "@/common/commonMethod";
import "./language/index";
//import { switchLanguages } from "./language/index";
import Locale from "./language/index";
import filters from "./components/filters";
import { getRoute } from "@/plugins/environment/type.js"; // 判断是否在app环境
import VideoPlayer from "vue-video-player";
import VueClipboard from "vue-clipboard2";

require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

Vue.use(VideoPlayer);
Vue.use(Api);
Vue.use(Locale);
Vue.use(filters);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  envType(to.query);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (store.getters.isLogging) {
      next();
    } else {
      next({
        path: getRoute() + "auth/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
