//import { Locale } from "vant";
//import zhCN from "vant/lib/locale/lang/zh-CN";
//let messages = zhCN;
// Locale.add(messages);
var messages = {
  privacy: {
    title: "Vancheen服务使用提示",
    text1: "感谢您的信任，欢迎使用Vancheen！",
    text2: "为更好地保护您的权益，请您务必仔细阅读并充分理解Vancheen的",
    text3: "《个人用户服务协议》及《隐私政策》",
    text4:
      "如您点击“同意”，即表明您已充分阅读、理解并接受上述协议的全部内容。如您“不同意”本协议及其补充协议中的任意条款约定，您可以退出本页面。",
  },
  meta: {
    homePage: "首页",
    followPage: "关注",
    productDetails: "商品详情",
    productList: "商品列表",
    supplierDetails: "供应商详情",
    earnings: "收益",
    myStore: "我的小店",
    commodityManage: "商品管理",
    indent: "买家订单",
    operatingData: "经营分析",
    indentDetails: "订单详情",
    withdraw: "提现",
    search: "搜索",
    allProducts: "全部分类",
    interpret: "翻译",
    setPrice: "修改价格",
    declaration: "声明",
    userPage: "用户",
    enterMyStore: "进入小店",
    shareMyStore: "分享小店",
    editMyStore: "编辑小店",
    sharedPage: "已转发",
    goCheck: "去看看",
    allPromotions: "全部促销",
    more: "更多",
    checkNow: "查看",
    Profitable: "折扣商品",
    Agreement: "个人用户服务协议",
    privacy: "隐私政策",
    agree: "同意",
    disagree: "不同意",
    submit: "提交",
    viewShop: "进店逛逛",
    videoError: "此视频暂无法播放，请稍后再试",
    messageText(value) {
      return "你有 " + value + " 条消息";
    },
    storeroom: "商品库",
    preferred: "首选",
    discount: "免费折扣",
    minSpend: "最低价",
    nextworkError: "网络错误",
    filter: "筛选",
    allFilter: "全部筛选",
    isCouponProduct: "优惠商品",
    isBrandProduct: "品牌商品",
    isAttestationProduct: "认证商品",
    attType: "认证类型",
    cSelect: "点击选择"
  },
  messages: {
    messageCenter: "消息中心",
    isTop: "置顶",
    unread: "未读",
  },
  couponInterpret: {
    state1: "可领取",
    state2: "待分享",
    state3: "已分享",
    state4: "已失效",
    state5: "已领完",
    state6: "已使用",
    shareTo: "已分享至",
    goShare: "去分享",
    endDate: "到期时间",
    addCoupon: "立即领取",
    noCondition: "无门槛",
    selectionTitle: "请选择粉丝",
    editPriceTxt1: "优惠商品不能编辑,请删除后重新收藏上架",
    editPriceTxt2: "利润必须为非负数",
    condition(value) {
      return "满" + value + "使用";
    },
    maxCount(value) {
      return "限领" + value + "张";
    },
    couponCount(value) {
      return value + "元优惠券";
    },
  },
  okText: "确定",
  resetText: "重置",
  second: "秒",
  name: "姓名",
  tel: "电话",
  save: "保存",
  done: "保存",
  saveSuccess: "保存成功",
  saveFail: "保存失败",
  confirm: "确认",
  cancel: "取消",
  delete: "删除",
  complete: "完成",
  loading: "加载中...",
  telEmpty: "请填写电话",
  nameEmpty: "请填写姓名",
  confirmDelete: "确定要删除么",
  telInvalid: "请填写正确的电话",
  forwardToEarn: "转发赚",
  //home国际化
  up: "起",
  sell: "已售",
  // piece: "件",
  piece(value) {
    if (!value) {
      return "件";
    } else {
      return "已售" + value + "件";
    }
  },
  noMoreData: "更多精品即将上线!",
  productDescription: "产品描述",
  noDetails: "暂无描述",
  supplier: "供应商",
  in: "逛逛",
  addToStore: "收藏至我店",
  savedToStore: "已收藏至我店",
  forwardGoods: "转发卖货",
  belongToSupplier: "你是供应商的普通分销",
  notAreToSupplier: "你不是供应商的普通分销",
  attention: "加关注",
  hasAttention: "已订阅",
  operateSuccessfully: "操作成功",
  forward: "转发",
  //我的小店
  shopAvatar: "店铺头像",
  shopName: "店铺名称",
  shopDesc: "店铺描述",
  todayTurnover: "今日营业额",
  todayOrder: "今日的订单数",
  visitorsToday: "今日的房客数",
  earnings: "收益管理",
  commodity: "商品管理",
  commodityDeleted: "该商品已被删除",
  indent: "订单管理",
  buyerOrder: "买家订单",
  operatingData: "经营分析",
  discount: "优惠",
  brand: "品牌",
  attestation: "认证",
  att1: "品牌Logo",
  att2: "品牌授权书",
  att3: "商标注册证",
  attestations: "商品认证",
  remark: "备注",
  coupon: "优惠券",
  fans: "我的粉丝",
  createTime: "注册时间",
  tutorials: "教程",
  shareMyStore: "复制链接后粘贴浏览器打开",
  soldOut: "下架中",
  sold: "下架",
  putaway: "上架中",
  put: "上架",
  all: "全部商品",
  sellingPrice: "售价",
  sales: "已售",
  profitAmount: "利润金额",
  profitScale: "利润比例",
  earn: "赚",
  totalInventory: "库存总额",
  inventoryState1: "库存不足",
  inventoryState2: "已售罄",
  interpret: "翻译",
  unstick: "取消置顶",
  stick: "置顶",
  updated: "更新",
  updatePrice: "修改价格",
  mainHeading: "主标题",
  subheading: "副标题",
  specification: "规格",
  cost: "成本",
  profit: "利润",
  inventory: "库存",
  msetKeyValue: "批量设置利润",
  allOrders: "全部订单",
  notDeliverGoods: "待发货",
  confiscatedGoods: "待收货",
  haveFinished: "已完成",
  todayData: "今日数据",
  turnover: "营业额",
  netIncome: "净收入",
  orderQuantity: "订单数",
  visitor: "访客数（人）",
  historicalData: "历史数据",
  historySearch: "历史搜索",
  cumulativeTurnover: "累计营业额",
  cumulativeNetIncome: "累计净收入",
  cumulativeOrder: "累计订单数",
  cumulativeVisitors: "累计访客数（人）",
  newProduct: "今日新上",
  ladies: "女装服饰",
  digital: "数码产品",
  hotSale: "爆款热卖",
  myOrder: "我的订单",
  waitPayment: "待付款",
  waitGoods: "待发货",
  haveGoods: "已收货",
  unfollow: "取消关注",
  cancelCollection: "取消收藏",
  profilePhoto: "头像",
  nickname: "昵称",
  myShippingAddress: "我的收货地址",
  logInSuccess: "登录成功",
  logOut: "退出登录",
  logisticsStatus: "物流状态",
  consignee: "收件人",
  totalItems(value) {
    return "共 " + value + " 件商品";
  },
  totalAmount: "总计",
  shippingAddress: "收件地址",
  productTotalPrice: "货物总价",
  freight: "运费",
  actualPayment: "实付款",
  total: "合计",
  contactTheShop: "联系店铺",
  orderNumber: "订单号",
  orderTime: "下单时间",
  timeOfPayment: "付款时间",
  payment: "已付款",
  product: "货物",
  totalPrice: "总金额",
  copy: "复制",
  copySuccess: "已复制到粘贴板",
  nonsupportCopy: "此设备不支持",
  contactSeller: "联系买家",
  remindSupplierOfShipment: "提醒供应商发货",
  pleaseTranslate: "请输入翻译",
  orderEdit: {
    title: "订单详情",
    total(val) {
      return "共" + val + "件商品";
    },
    expressState() {
      return "退还给买家";
    },
    refundToBuyer: "退还给买家",
    waitingForTheBuyerToPay: "等待买家付款中",
    closeOrder: "关闭订单",
    WaitingConfirmCollection: "等待买家确认收款",
    confirmReceipt: "确认收款",
  },
  earningsInfo: {
    orderIncomes: "订单收益",
    paymentDeclaration: "阳光结汇",
    paymentWithdrew: "资金下发",
    myBalance: "可提余额",
    withdraw: "提现",
    total: "总收益",
    frozen: "冻结金额",
    buyerPayment: "买家付款",
    frozenPayment: "冻结贷款",
    collectedPayment: "确认收款",
    paymentRelease: "贷款解冻",
    balanceWithdraw: "提现贷款",
    skuInfo:
      "微信在买家支付是会扣除订单金额的0.6%作为手续费Vancheen不会扣除任何费用",
    incomeRecords: "收款记录",
    withdrawRecords: "提现记录",
    poNo: "订单",
    bankName: "转账到银行卡",
    banksInfo: "预计在1工作日内到账",
    amountWithdraw: "提现金额",
    withdrawNum(value) {
      return "可提现金额 $ " + value + " 元";
    },
    allWithdraw: "全部提现",
    withdrawBt: "提现",
  },
  //登录页面
  loginInterpret: {
    login: "登录",
    inputUsername: "请输入用户名或者邮箱",
    inputPassword: "请输入密码",
    userName: "用户名",
    password: "密码",
    authCode: "验证码",
    phoneNumber: "手机号",
    verificationCodeError: "请先填写用户名与密码",
    download: "下载图片",
    saveText: "长按图片保存分享",
    copyText: "复制邀请链接",
  },
  //注册页面
  registerInterpret: {
    register: "注册",
    inputEmail: "请输入邮箱",
    email: "邮箱",
    code: "验证码",
    inputCode: "请输入验证码",
    next: "下一步",
    tick: "请勾选",
    rulesAndProtocols: "我已阅读并同意个人用户服务协议和隐私政策",
    verificationCodeInfo: "已发送验证码",
    pleaseEnterEmail: "请先输入正确的邮箱",
    verificationCodeErroe: "请先填写验证码与正确邮箱",
  },
  //注册页面
  regInfoInterpret: {
    pasTitle: "密码",
    inputPaw: "请输入登录密码",
    pawInfo: "密码由6-20位英文字母+数字+符号组成",
    inputName: "请输入用户名",
    userName: "用户名",
    nameInfo: "5-25个字符，可用于登录或者找回密码",
    confirm: "确认",
    correctError: "请先输入正确的密码",
    userNameError: "请先输入正确的用户名",
    registerSuccess: "注册成功",
    registerInfo: "注册成功,是否跳转App下载页",
    inputInviteCode: "请输入邀请码(非必填)",
    inviteCode: "邀请码",
    backLogin: "返回登录",
  },
  //忘记密码
  forgetInterpret: {
    forgetPassword: "忘记密码",
    validEmail: "验证邮箱",
    setNewPassword: "设置新密码",
    finishPasswrod: "完成",
    forgottenPassword: "忘记密码?",
    resetPasswordStep1: "1.验证注册邮箱", // 1.Verify registered email
    resetPasswordStep2: "2.验证邮箱", // 2.Verify code
    resetPasswordStep3: "3.设置新密码", // 3.Set new password
    resetPasswordStep4: "4.完成", // 4.Finish
    next: "下一步",
    noAccountGoRegister: "没有账号？去注册",
    backLogin: "返回登录",
    placeholderEmail: "请输入邮箱",
    placeholderEmailCode: "请输入邮箱验证码",
    noAccountGoRegister: "没有账号？去注册",
    sendAuthCode: "获取验证码",
    errorEmail: "请输入正确的邮箱地址",
    errorEmailNotExist: "账号不存在",
    placeholderNewPassword: "请输入新的密码",
    passwordValid: "密码由6-20位英文字母、数字或符号组成",
    placeholderAuthPassword: "确认密码",
    errorPasswordNotMatch: "新的密码与确认密码不一致",
  },
  //用户中心
  userInterpret: {
    ShippingSource: "已转发货源",
    MySupplier: "我的供应商",
    ShopOrder: "今日小店订单",
    ShopVisitor: "今日小店访客",
    Manage: "综合管理",
    Settings: "设置",
    Invite: "邀请码",
    ProductSell: "件商品在售",
    editName: "修改昵称",
    inputNewName: "请输入新的昵称",
    inputYourName: "请输入您的昵称",
    messageCenter: "消息中心",
    noName: "无名称"
  },
  support: {
    title: "技术支持",
    dateHour: "工作时间：9:00-18:00",
    dateWeek: "工作天数: Monday – Friday",
    helpCenter: "帮助中心",
    email: "邮箱",
    select: "请选择服务类型",
    supportOption1: "投诉与建议",
    supportOption2: "其他",
    enterType: "请选择类型",
    enterSupport: "请输入你的投诉与建议内容",
    enterEmail: "请输入邮箱地址",
    contactWay: "你的联系方式",
    supportList: "查看已提交的内容",
    serviceType: "服务类型",
    serviceTitle: "问题标题",
    serviceStatus: "问题状态",
    serviceTime: "提交时间",
    serviceDes: "问题描述",
    serviceContact: "联系方式",
    servicePosted: "发表人",
    serviceContent: "回复内容",
    serviceReplyTime: "回复时间",
    serviceReplyContent: "回复内容",
    serviceInputReplyContent: "请输入回复内容",
    copyRight: "版权所有",
    currentVersion: "当前版本",
    updateVersion: "版本更新"
  },
  authenticationInfo: {
    haveAuth: "已认证",
    unAuth: "未认证",
    onAuth: "审核中",
    reAuth: "未通过",
    passPort: "驾照号/护照号",
    inputPassPort: "请输入驾照号/护照号",
    auth: "认证",
    nameAuth: "实名认证",
    checkAuth: "身份认证",
    goAuth: "去认证",
    title: "请上传清晰的驾驶证或护照图片（正反面）",
    yourName: "名字",
    yourNamePlc: "请输入名字",
    bankName: "银行名称",
    bankNamePlc: "请输入银行名称",
    bankAccount: "银行账号",
    bankAccountPlc: "请输入银行账号",
    iBankNo: "IBank No.",
    iBankNoPlc: "请输入 IBank No.",
    swiftCode: "Swift Code",
    swiftCodePlc: "请输入 Swift Code",
    bankAddress: "银行地址",
    bankAddressPlc: "请输入银行地址",
    yourPostAdd: "邮寄地址",
    yourPostAddPlc: "请输入邮寄地址",
    done: "完成",
    reason: "原因",
    authMessage: "您未完成实名认证，请前往完成验证",
    submitMessage: "您的身份认证正在审核中，请耐心等候",
    failMessage: "您的身份认证未通过，请重新认证身份",
    shopName: "店铺名称",
    yourShopName: "请输入店铺名称",
  },
  settings: {
    accountDelete: "账号注销",
    accountDeleteSuccess: "账号注销成功",
    aboutUs: "关于我们",
    label1: "信息修改问题",
    label2: "购物体验问题",
    label3: "其他问题",
    issue1: "无法修改账号名",
    issue2: "手机停用",
    issue3: "修改邮箱",
    issue4: "多余的账号",
    issue5: "交易维权不满意",
    issue6: "支付遇到问题",
    issue7: "安全隐私顾虑",
    issue8: "其他问题",
    inputText: "请输入其他问题",
    nextStep: "下一步",
    error: "请选择问题",
    termsText: "我已阅读并同意《账号注销重要提醒》"
  },
  searchInfo: {
    general: "综合",
    sales: "成交数",
    forwards: "转发数"
  },
  alert: {
    cancelButtonText: "取消",
    confirmButtonText: "确认",
    copyButtonText: "复制",
    authButtonText: "立即认证",
    deleteText: "您确认删除吗?",
    shopText: "您的店铺没有上传店铺头像，请先设置头像后再分享",
    goUpload: "去上传",
    cancellationText: "确认注销账号?",
  },
  cStore: {
    hotSale: "热销榜",
    allProducts: "全部商品",
    sold: "已售",
    pleaseEnterContent: "请输入内容",
  },
  plugins: {
    unavailable: "此功能需要访问客户端才能使用",
    download: "去下载",
  },
};
export default messages;
