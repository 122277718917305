//import { Locale } from "vant";
//import enUS from "vant/lib/locale/lang/en-US";
var messages = {
  privacy: {
    title: "Vancheen Service Usage Tips",
    text1: "Thank you for your trusty, welcome using Vancheen!",
    text2:
      "In order to better protect your rights and interests, please carefully read and fully understand",
    text3: "Individual User Service Agreement and Privacy Policy.",
    text4:
      "If you click 'agree', means that you have fully read, understood and accepted all the contents of this agreement, and this agreement will be legally binding. if you click “not agree”, you can exit from this page.",
  },
  meta: {
    homePage: "Home",
    followPage: "Follow",
    productDetails: "Product details",
    productList: "Product list",
    supplierDetails: "Supplier details",
    earnings: "Incomes",
    myStore: "My store",
    commodityManage: "Commodity management",
    indent: "Purchase Orders",
    operatingData: "Operating data",
    indentDetails: "Purchase Orders details",
    withdraw: "Withdraw",
    search: "Search for a product",
    allProducts: "All Products",
    interpret: "Interpret",
    setPrice: "set price",
    declaration: "Declaration",
    userPage: "Me",
    enterMyStore: "Enter Store",
    shareMyStore: "Share Store",
    editMyStore: "Edit Store",
    sharedPage: "Shared",
    goCheck: "Go Check",
    allPromotions: "ALL PROMOTIONS",
    more: "MORE >",
    checkNow: "Check Now",
    Profitable: "PROFITABLE ITEMS FORWARD",
    Agreement: "User Service Agreement",
    privacy: "Privacy Policy",
    agree: "Agree",
    disagree: "Disagree",
    submit: "Submit",
    viewShop: "See more",
    videoError:
      "This video is not available at this time, please try again later",
    messageText(value) {
      return "you have " + value + " new messages";
    },
    storeroom: "STOREROOM",
    preferred: "preferred",
    discount: "free discount",
    minSpend: "MIN SPEND",
    nextworkError: "Network error",
    filter: "Filter",
    allFilter: "All conditions",
    isCouponProduct: "Discount goods",
    isBrandProduct: "Brand goods",
    isAttestationProduct: "Certified goods",
    attType: "Certification type",
    cSelect: "Please select"
  },
  messages: {
    messageCenter: "Message Center",
    isTop: "Top",
    unread: "Unread",
  },
  couponInterpret: {
    state1: "Claimable",
    state2: "Shareable",
    state3: "Shared",
    state4: "Unavailable",
    state5: "No more",
    state6: "have been used",
    shareTo: "Have been shared to",
    goShare: "Share",
    endDate: "Maturity time",
    addCoupon: "Claim",
    noCondition: "No threshold",
    selectionTitle: "Please selet a fan",
    editPriceTxt1: "Discount items cannot be edit, you can publish after delete",
    editPriceTxt2: "Profits must be non-negative",
    condition(value) {
      return "Use up to " + value;
    },
    maxCount(value) {
      return "Limit " + value;
    },
    couponCount(value) {
      return "¥ " + value + " coupon";
    },
  },
  okText: "OK",
  resetText: "Reset",
  second: "S",
  name: "Name",
  tel: "Phone",
  save: "Save",
  done: "Done",
  saveSuccess: "Save success",
  saveFail: "Save error",
  confirm: "Confirm",
  cancel: "Cancel",
  delete: "Delete",
  complete: "Complete",
  loading: "Loading...",
  telEmpty: "Please fill in the tel",
  nameEmpty: "Please fill in the name",
  confirmDelete: "Are you sure you want to delete?",
  telInvalid: "Malformed phone number",
  forwardToEarn: "Earn",
  up: "Up",
  sell: "sell",
  // piece: "piece",
  piece(value) {
    if (!value) {
      return "pcs Sold";
    } else {
      return value + "pcs Sold";
    }
  },
  noMoreData: "More wonderful are coming soon!",
  productDescription: "Product description",
  noDetails: "No details",
  supplier: "Supplier",
  in: "Browse",
  addToStore: "Collect in My Store",
  savedToStore: "Saved to my store",
  forwardGoods: "Forward goods",
  belongToSupplier: "You are a regular distributor of suppliers",
  notAreToSupplier: "You are not an ordinary distributor of suppliers",
  attention: "Follow",
  hasAttention: "Subscribed",
  operateSuccessfully: "Operate successfully",
  forward: "Forward",
  //我的小店
  shopAvatar: "Shop Avatar",
  shopName: "Shop Name",
  shopDesc: "Shop Description",
  todayTurnover: "Today's turnover",
  todayOrder: "Today's order",
  visitorsToday: "Visitors today",
  earnings: "My Revenue",
  commodity: "Items Center",
  commodityDeleted: "Items has been deleted",
  indent: "PO Management",
  buyerOrder: "Buyer Order",
  operatingData: "Data Analysis",
  discount: "Discount",
  brand: "Brand",
  attestation: "Attestation",
  att1: "Brand logo",
  att2: "Brand authorization letter",
  att3: "Trademark registration certificate",
  attestations: "Attestations",
  remark: "Remark",
  coupon: "Coupon",
  fans: "Fans",
  createTime: "Create time",
  tutorials: "Vancheen Q & A",
  shareMyStore: "Copy the link and paste the browser to open",
  soldOut: "Off Shelf",
  sold: "Off Shelf",
  // putaway: "Putaway",
  putaway: "On Shelf",
  put: "On Shelf",
  all: "All Items",
  sellingPrice: "Price",
  sales: "Sold",
  profitAmount: "Profit Amount",
  profitScale: "profit Scale",
  earn: "Earn",
  totalInventory: "Total inventory",
  inventoryState1: "Under stock",
  inventoryState2: "Out of stock",
  interpret: "Edit",
  unstick: "On Top",
  stick: "on top",
  updated: "Updated",
  updatePrice: "Modify Price",
  mainHeading: "Main heading",
  subheading: "Subheading",
  specification: " Package",
  cost: "Cost",
  profit: "Profit",
  inventory: "Inventory",
  msetKeyValue: "Set Profit Percentage",
  allOrders: "All POs",
  notDeliverGoods: "PO to be Send",
  confiscatedGoods: "PO to be confirm",
  haveFinished: "Completed POs",
  todayData: "Today's data",
  turnover: "Revenue",
  netIncome: "Profit",
  orderQuantity: "POs",
  visitor: "Visitors",
  historicalData: "Data Records",
  historySearch: "Search history",
  cumulativeTurnover: "Cumulative Revenue",
  cumulativeNetIncome: "Cumulative Profit",
  cumulativeOrder: "Cumulative POs",
  cumulativeVisitors: "Cumulative Vistors",
  newProduct: "New",
  ladies: "Ladies",
  digital: "Digital",
  hotSale: "Hot sale",
  myOrder: "My order",
  waitPayment: "wait payment",
  waitGoods: "wait Goods",
  haveGoods: "Have goods",
  unfollow: "Unfollow",
  cancelCollection: "Cancel collection",
  profilePhoto: "Profile photo",
  nickname: "Nickname",
  myShippingAddress: "My shipping address",
  logInSuccess: "Log In success",
  logOut: "Log out",
  logisticsStatus: "Logistics status",
  consignee: "Buyer",
  totalItems(value) {
    return "Total " + value + " Items";
  },
  totalAmount: "Total Amount",
  shippingAddress: "Consignee Address",
  productTotalPrice: "Product total price",
  freight: "Delivery Fee",
  actualPayment: "Actual payment",
  total: "Total",
  contactTheShop: "Contact the shop",
  orderNumber: "PO No",
  orderTime: "Order Date",
  timeOfPayment: "Payment Date",
  payment: "Paid Amount",
  product: "Product",
  totalPrice: "total price",
  copy: "copy",
  copySuccess: "Copy success",
  nonsupportCopy: "Nonsupport copy",
  contactSeller: "Contact Seller",
  remindSupplierOfShipment: "Reminder Supplier to send out the Items",
  pleaseTranslate: "Please Translate",
  orderEdit: {
    title: "line item",
    total(val) {
      return "A total of " + val + " items";
    },
    refundToBuyer: "Refund To Buyer",
    waitingForTheBuyerToPay: "Waiting For The Buyer To Pay",
    closeOrder: "Close Order",
    WaitingConfirmCollection: "Waiting For The Buyer To Confirm The Collection",
    confirmReceipt: "Confirm Receipt",
  },
  earningsInfo: {
    orderIncomes: "Order Incomes",
    paymentDeclaration: "Payment Declaration",
    paymentWithdrew: "Payment Withdrew",
    myBalance: "My Balance",
    withdraw: "Withdraw",
    total: "Total Income",
    frozen: "Receivable payment",
    buyerPayment: "Buyer Payment",
    frozenPayment: "Frozen Payment",
    collectedPayment: "Collected Payment",
    paymentRelease: "Payment Release",
    balanceWithdraw: "Balance Withdraw",
    skuInfo:
      "Incase you use WeChat payment system, Wechat will charge 0.6% handling fee from your payment, and Vancheen will not charge any fees.",
    incomeRecords: "Income Records",
    withdrawRecords: "Withdraw Records",
    poNo: "PO No",
    bankName: "Bank's Name",
    banksInfo:
      "This payment transfer application will be process on next working day.",
    amountWithdraw: "Amount Withdraw",
    withdrawNum(value) {
      return "Available Amount Withdraw $ " + value;
    },
    allWithdraw: "All Withdraw",
    withdrawBt: "Withdraw",
  },
  //登录页面
  loginInterpret: {
    login: "Log in",
    inputUsername: "Input Email",
    inputPassword: "Input Password",
    userName: "Username",
    password: "Password",
    authCode: "Verification Code",
    phoneNumber: "Phone number",
    verificationCodeError: "Verification username or password error",
    download: "Download Image",
    saveText: "Press and hold the picture to save and share",
    copyText: "Copy invitation link",
  },
  //注册页面
  registerInterpret: {
    register: "Sign up",
    inputEmail: "Input Email",
    email: "Email",
    code: "E-mail code",
    inputCode: "Input the Code",
    next: "Next",
    tick: "Please tick ",
    rulesAndProtocols:
      "I agreed to Individual User Service Agreement and Privacy Policy",
    verificationCodeInfo: "Verification code has been sent",
    pleaseEnterEmail: "Please enter",
    verificationCodeErroe: "Verification code or email error",
  },
  //注册页面
  regInfoInterpret: {
    pasTitle: "Password",
    inputPaw: "Please Enter Password",
    pawInfo: "6-20 Characters With Digit Mix Letter.",
    inputName: "Enter User Name",
    nameInfo: "5-25 Characters",
    userName: "User Name",
    confirm: "Done",
    correctError: "Please enter the correct password first",
    userNameError: "Please enter the correct user name first",
    registerSuccess: "Register successfully",
    registerInfo: "Register successfully and download the app",
    inputInviteCode: "Please input invitation code (optional)",
    inviteCode: "Invitation code",
    backLogin: "Back to login",
  },
  //忘记密码
  forgetInterpret: {
    forgetPassword: "Forget password",
    validEmail: "Validate e-mail address",
    setNewPassword: "Set a new password",
    finishPasswrod: "Finish",
    forgottenPassword: "Forgot Your Password?",
    resetPasswordStep1: "1.Verify registered email", //
    resetPasswordStep2: "2.Verify code", //
    resetPasswordStep3: "3.Set new password", //
    resetPasswordStep4: "4.Finish", //
    next: "Next",
    noAccountGoRegister: "No have an account? Register now",
    backLogin: "Back to login",
    placeholderEmail: "Input E-mail",
    placeholderEmailCode: "Input the Code",
    noAccountGoRegister: "No have an account? Register now",
    sendAuthCode: "E-mail code",
    errorEmail: "Error Email address, try again",
    errorEmailNotExist: "Account is not exist",
    placeholderNewPassword: "Please enter your new password",
    passwordValid: "Password be 6-20 characters with digit mix letter.",
    placeholderAuthPassword: "Please enter again",
    errorPasswordNotMatch:
      "The new password does not match the confirmation password",
  },
  //用户中心
  userInterpret: {
    ShippingSource: "My sharing",
    MySupplier: "My supplier",
    ShopOrder: "Today's order",
    ShopVisitor: "Today's visitor",
    Manage: "Manage",
    Settings: "Settings",
    Invite: "Invite code",
    ProductSell: "Product Selling",
    editName: "Edit Name",
    inputNewName: "Input New Name",
    inputYourName: "Input Your Name",
    messageCenter: "Messages",
    noName: "No name"
  },
  support: {
    title: "Technical Support",
    dateHour: "Business hours : 9:00-18:00 (GMT +8)",
    dateWeek: "Business day : Monday – Friday",
    helpCenter: "Help center",
    email: "Email",
    select: "Please select a service",
    supportOption1: "Complaints and Suggestions",
    supportOption2: "Others",
    enterType: "Please choose the type",
    enterSupport: "Please input the content",
    enterEmail: "Please input the email address",
    contactWay: "Your contact information",
    supportList: "View submitted content",
    serviceType: "Service type",
    serviceTitle: "Question title",
    serviceStatus: "Problem status",
    serviceTime: "Submission time",
    serviceDes: "Problem Description",
    serviceContact: "Contact information",
    servicePosted: "Posted by",
    serviceContent: "Reply content",
    serviceReplyTime: "Response Time",
    serviceReplyContent: "Reply content",
    serviceInputReplyContent: "Please input the reply content",
    copyRight: "Copy right",
    currentVersion: "Current version",
    updateVersion: "Version update"
  },
  authenticationInfo: {
    haveAuth: "Verified successfully",
    unAuth: "Unverified",
    onAuth: "Verifying",
    reAuth: "Verification failed",
    passPort: "Driving License No. / Passport No.",
    inputPassPort: "Input Driving License No. / Passport No.",
    auth: "Authentication",
    nameAuth: "Authentication",
    checkAuth: "Verify",
    goAuth: "Go Verify",
    title:
      "Please upload clean image of your passport or driving license（front and back side）",
    yourName: "Real Name",
    yourNamePlc: "Your Name",
    bankName: "Bank Name",
    bankNamePlc: "Please Entry Bank Name Of Your Account.",
    bankAccount: "Bank Account",
    bankAccountPlc: "No.",
    iBankNo: "IBank No.",
    iBankNoPlc: "Please Entry Your IBank No.",
    swiftCode: "Swift Code",
    swiftCodePlc: "Swift Code",
    bankAddress: "Bank Address",
    bankAddressPlc: "Please Entry The Bank Address Of Your Account.",
    yourPostAdd: "Mailing Address",
    yourPostAddPlc: "Mailing Address",
    done: "Done",
    reason: "Reason",
    authMessage:
      "You have not completed the real name authentication, please go to complete the verification",
    submitMessage:
      "Your identity verification is under review, please wait patiently",
    failMessage:
      "Your identity verification failed, please re-verify your identity",
    shopName: "Shop name",
    yourShopName: "Please Enter Your Shop Name",
  },
  settings: {
    accountDelete: "Account Cancellation",
    accountDeleteSuccess: "Account deactivated successfully",
    aboutUs: "About us",
    label1: "Information modification issues",
    label2: "Shopping experience issues",
    label3: "Other issues",
    issue1: "Unable to change account name",
    issue2: "Mobile credit issues",
    issue3: "Change of email",
    issue4: "Duplicate account",
    issue5: "Unsatisfied with transaction dispute resolution",
    issue6: "Payment issues encountered",
    issue7: "Security and privacy concerns",
    issue8: "Other issues",
    inputText: "Please enter other issues",
    nextStep: "Next step",
    error: "Please select a issues",
    termsText: "I have read and agree to the 《Important Reminders for Account Cancellation》"
  },
  searchInfo: {
    general: "General",
    sales: "Sales",
    forwards: "Forwards"
  },
  alert: {
    cancelButtonText: "Cancel",
    confirmButtonText: "Confirm",
    copyButtonText: "Copy",
    authButtonText: "Go",
    deleteText: "Are you sure to delete?",
    shopText: "Your store has not uploaded the store avatar, please set the avatar before sharing",
    goUpload: "Go to upload",
    cancellationText: "Are you sure to Deactivate Account?",
  },
  cStore: {
    hotSale: "Hot Sale",
    allProducts: "All Products",
    sold: "Sold",
    pleaseEnterContent: "Please enter content",
  },
  plugins: {
    unavailable: "This feature requires access to the client to use",
    download: "Go download",
  },
};
export default messages;
