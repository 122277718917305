import axios from "axios";
import Loacle from "../language/index";
import { Language } from "../language";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASH_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": Loacle.language,
    "SystemType": isAPP() ? getPlatform() : 'web'
  },
});

instance.interceptors.request.use(
  (config) => {
    let { token } = JSON.parse(localStorage.getItem("auth")) || {};
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.StatusCode === 200) {
      return {
        code: 200,
        data: data.Data,
        message: data.Message,
        meta: {
          page: data.PageIndexs,
          total: data.DataCounts,
          lastPage: data.ALLPages,
        },
      };
    } else {
      const error = { code: data.StatusCode, message: data.Message };
      return Promise.reject(error);
    }
  },
  (error) => {
    let customError;
    if (error && error.response && error.response.data) {
      if (
        error.response.data.StatusCode === 403 ||
        error.response.data.StatusCode === 401
      ) {
        localStorage.removeItem("auth");
      }
    } else {
      customError = { code: 0, message: Language.meta.nextworkError, status: 400 };
    }
    return Promise.reject(customError);
  }
);

export default instance;
